import styled from 'styled-components';

export const ContainerBoxPreview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0px 0px 0px 42px;
  
  @media (max-width: 1346px) {
    margin: 0;
  }
  `;

export const ImagePreview = styled.img`
  height: 420px;
  width: 400px;
  pointer-events: none;

  @media (max-width: 425px) {
    height: 300px;
    width: 285px;
  }

  @media print {
    display: none;
  }
`;

export const ContainerBuilder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 736px;
  margin: 0px 62px;

  @media (max-width: 1346px) {
    margin: 0;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;

  position: absolute;

  right: 100px;

  @media (max-width: 1540) {
    right: 50px;
  }

  @media (max-width: 1346px) {
    bottom: 50px;
  }

  @media (max-width: 425px) {
    width: 210px;
  }
`;
