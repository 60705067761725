import styled from 'styled-components';

export const ContainerHomeForm = styled.div`
  margin-top: 30px;

  @media (max-width: 1346px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerInputName = styled.div`
  @media (max-width: 1346px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 30px;
  }
`;

export const ContainerInputGender = styled.div`
  @media (max-width: 1346px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 93%;
  }
`;