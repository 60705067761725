import axios from 'axios';

export const instanceApiDoare = axios.create({    
  baseURL: process.env.NEXT_PUBLIC_API_V2_URL || 'https://api-production.doare.org/api/v2', 
});

export const instanceApiNFTPort = axios.create({    
  baseURL: process.env.NEXT_PUBLIC_API_NFT_PORT || 'https://api.nftport.xyz/v0',
  headers: {
    common: {
      Authorization: process.env.NEXT_PUBLIC_API_NFT_PORT_ACCESS_TOKEN || 'd9716352-250f-4254-a822-de1325d962ba'
    }
  }
});