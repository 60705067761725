import { Controller, useFormContext } from "react-hook-form";
import { useAuth } from "../../../hooks/auth";
import { Column, Icon, Row, Typography } from "@doare/ui-components";
import { FieldSet, Label, Input, ToggleGroup, ToggleGroupItem } from "../form.styles";
import { FemaleIcon, MaleIcon } from "../../icons/icons.component";
import { ContainerHomeForm, ContainerInputGender, ContainerInputName } from "./styles";

export const HomeForm = () => {
  const { control } = useFormContext();
  const { setDogName, dogName } = useAuth();

  return (
    <ContainerHomeForm>
      <ContainerInputName>
        <FieldSet>
          <Label>Nome do cachorro</Label>
          <Input value={dogName} onChange={(e) => setDogName(e.target.value)} />
        </FieldSet>
      </ContainerInputName>
      <ContainerInputGender>
        <FieldSet>
          <Label>Gênero</Label>
          <Controller
            name="gender"
            control={control}
            render={({ field: { value: _value, onChange } }) => (
              <ToggleGroup
                value={_value}
                type="single"
                defaultValue="male"
                aria-label="Gender"
                onValueChange={(value) => {
                  onChange(value ? (value as any) : _value)
                }
                }
              >
                <div style={{ display: "flex", flexDirection: "column"}}>
                  <ToggleGroupItem value="male" aria-label="Male">
                    <Icon size={24}>
                      <MaleIcon />
                    </Icon>
                  </ToggleGroupItem>
                  <Typography>Macho</Typography>
                </div>
                <div style={{ display: "flex", flexDirection: "column"}}>
                  <ToggleGroupItem value="female" aria-label="Male">
                    <Icon size={24}>
                      <FemaleIcon />
                    </Icon>
                  </ToggleGroupItem>
                  <Typography>Fêmea</Typography>
                </div>
              </ToggleGroup>
            )}
          ></Controller>
        </FieldSet>
      </ContainerInputGender>
    </ContainerHomeForm>
  );
};