import React, { useCallback, useEffect, useState } from "react";

import { NextPage } from "next";
import { useRouter } from "next/router";

import { FormProvider } from "react-hook-form";
import { useAuth } from "../../hooks/auth";

import { Icon, Typography } from "@doare/ui-components";
import {
  Card,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  ArmsForm,
  BodyAndEyesForm,
  BreedForm,
  CapAndNecklaceForm,
  ClothesAndGlassesForm,
  FrameForm,
  ViewForm,
  ClosedHandIcon,
  DogHomeIcon,
  DogIcon,
  GirlBowIcon,
  PawIcon,
  TShirtIcon,
  ViewIcon,
  VisionIcon,
  Button,
} from "../../components";
import { ContainerBoxPreview, ContainerBuilder, ContainerButtons, ImagePreview } from "../../components/app/styles";

import { instanceApiDoare, instanceApiNFTPort } from "../../services/ApiService";
import { HomeForm } from "../../components/form/HomeForm";
import { useBuilder } from "../../hooks/builder";

const App: NextPage = () => {
  const router = useRouter();
  const { setIpfsUrl, price, sku, dogName } = useAuth();
  const { makeImagePreview, previewImage, form, breed, gender, view, body, eyes, clothes, frame } = useBuilder();
  const [ isMinting, setIsMinting ] = useState(false);

  const [tab, setTab] = useState<string>("1");

  useEffect(() => {
    makeImagePreview();
  }, [makeImagePreview]);

  const resetForm = async () => {
    const genderModified = await form.getFieldState('gender').isDirty;
    const breedModified = await form.getFieldState('breed').isDirty;

    if (genderModified || breedModified) {
      form.reset({
        breed: breed,
        gender: gender,
        view: view,
      });
    }
  }

  useEffect(() => {
    resetForm();
  }, [gender, breed]);

  const isLastStep = Number(tab) === 8;

  const isNextStepDisabled =  (Number(tab) < 2 && (!dogName)) || ((!body || !eyes) && Number(tab) >= 4) || ((!body || !eyes || !clothes) && Number(tab) >=5);

  const isNextStepEnabled =
    (((!gender || !breed) && Number(tab) < 2) ||
    (gender && breed && Number(tab) < 8)) && !isNextStepDisabled;

  const onHandleMintNft = useCallback(async () => {
    try {
      setIsMinting(true);
      const skuIsAvailable = await instanceApiDoare.get(`nft/validate-sku/${sku}`);

      if (!skuIsAvailable.data) {
        alert('Essa ARTE/NFT já existe. Mude alguns itens para que a sua seja única.');
        return;
      }

      const splitDataURI = previewImage.split(',');
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0]?.split(':')[1]?.split(';')[0] || 'image/png';

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);

      const file = new Blob([ia], { type: mimeString });
      
      const form = new FormData();
      form.append('file', file, 'mydog.png');

      const response = await instanceApiNFTPort.post('/files', form);

      if (!response?.data?.ipfs_url) {
        alert('Erro na geração da NFT. Tente novamente');
      }

      setIpfsUrl(response?.data?.ipfs_url);
      router.push(`/checkout`);
      setIsMinting(false);
    } catch (error) {
      console.log(error);
      alert('Erro na geração da NFT. Tente novamente');
      setIsMinting(false);
    }
  }, [previewImage]);

  return (
    <FormProvider {...form}>
      <Card>
        <ContainerBoxPreview>
          {previewImage && (
            <ImagePreview
              src={previewImage}
              alt="Character"
            />
          )}
          <Typography style={{ color: "#1E120D", marginTop: 4}}>Preço: {price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</Typography>
        </ContainerBoxPreview>
        <ContainerBuilder>
          <Tabs
            value={tab}
            onValueChange={(value) => setTab(value)}
            defaultValue="1"
          >
            <TabsList aria-label="Manage your account">
              <TabsTrigger value="1">
                <Icon size={28}>
                  <DogHomeIcon />
                </Icon>
              </TabsTrigger>
              <TabsTrigger disabled={(!dogName)} value="2">
                <Icon size={28}>
                  <PawIcon />
                </Icon>
              </TabsTrigger>
              <TabsTrigger disabled={(!gender || !breed || !dogName)} value="3">
                <Icon size={28}>
                  <ViewIcon />
                </Icon>
              </TabsTrigger>
              <TabsTrigger disabled={(!gender || !breed || !dogName)} value="4">
                <Icon size={28}>
                  <DogIcon />
                </Icon>
              </TabsTrigger>
              <TabsTrigger disabled={(!gender || !breed || !dogName || !body || !eyes)} value="5">
                <Icon size={28}>
                  <TShirtIcon />
                </Icon>
              </TabsTrigger>
              <TabsTrigger disabled={(!gender || !breed || !dogName || !body || !eyes || !clothes)} value="6">
                <Icon size={28}>
                  <GirlBowIcon />
                </Icon>
              </TabsTrigger>
              <TabsTrigger disabled={(!gender || !breed || !dogName || !body || !eyes || !clothes)} value="7">
                <Icon size={28}>
                  <ClosedHandIcon />
                </Icon>
              </TabsTrigger>
              <TabsTrigger disabled={(!gender || !breed || !dogName || !body || !eyes || !clothes)} value="8">
                <Icon size={28}>
                  <VisionIcon />
                </Icon>
              </TabsTrigger>
            </TabsList>
            <TabsContent value="1">
              <HomeForm />
            </TabsContent>
            <TabsContent value="2">
              <BreedForm />
            </TabsContent>
            <TabsContent value="3">
              <ViewForm />
            </TabsContent>
            <TabsContent value="4">
              <BodyAndEyesForm />
            </TabsContent>
            <TabsContent value="5">
              <ClothesAndGlassesForm />
            </TabsContent>
            <TabsContent value="6">
              <CapAndNecklaceForm />
            </TabsContent>
            <TabsContent value="7">
              <ArmsForm />
            </TabsContent>
            <TabsContent value="8">
              <FrameForm />
            </TabsContent>
          </Tabs>
        </ContainerBuilder>
        <ContainerButtons>
          <Button 
            style={{ background: isMinting ? "#cccccc" : "#FFFFFF" }} 
            isNotVisible={!isLastStep || !frame}
            disabled={isMinting}
            onClick={() =>
              onHandleMintNft()
            }
          >
            Mintar NFT
          </Button>
          <Button
            onClick={() =>
              setTab(isNextStepEnabled ? String(Number(tab) + 1) : tab)
            }
            isNotVisible={!isNextStepEnabled}
          >
            Próximo passo
          </Button>
        </ContainerButtons>
      </Card>
    </FormProvider>
  );
};

export default App;
